.scrollToTop-button {
  border-radius: 30px !important;
  background-color: #083f88 !important;
  bottom: 20px !important;
  right: 20px !important;
}

.scrollToTop-arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transform: rotate(90deg);
  font-size: 25px;
  margin-right: 0.1rem;
  margin-top: -0.35rem;
  letter-spacing: -6px;
}