/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
*/

.title, .lo-website, .lo-banner-name{
  color: #EE8524;
  font-weight: 600;  
}
.lo-banner-nmls {
  color: #fff;
  font-weight: 500;
  text-align: center;
}
.banner-para {
  color: #fff;
  font-weight: 300;
  text-align: justify;
}
.lo-banner-mob {
  color: #fff;
  font-weight: 300;
  text-align: center;
}
.lo-banner-mob a{
  color: #fff;
  text-decoration: none;
}
.lo-banner-mob a:hover{
  text-decoration: underline;
}
@media only screen and (min-width: 992px) {
  .banner {
    background-image: url("../../Images/deskbg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
    padding-bottom: 15rem;

    /* Animation properties */
    animation-name: bannerAnimation;
    animation-duration: 0.5s; /* Adjust the duration as needed */
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); /* Spring easing */
    animation-fill-mode: forwards;
    animation-delay: 100ms; /* Apply animation delay */

    /* Other styles for the element */
  }

  /* Define the keyframes for the animation */
  @keyframes bannerAnimation {
    from {
      opacity: 0;
      transform: translateY(-20px); /* Adjust as needed */
    }

    to {
      opacity: 1;
      transform: translateY(0); /* Adjust as needed */
    }
  }
  .title{ 
    margin-top: 10rem;
    font-size: 3rem;
  }
  .lo-website{
    font-size: 3.5rem;
  }
  .banner-para {
    margin-top: 2rem;
    font-size: 1.1rem;
  }
  .lo-banner-col{
    margin-top: 4rem;
    text-align: center;
  }
  .lo-banner-name{
    margin-top: -2rem;
  }
  .lo-banner-nmls {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1199.9px) and (min-width: 992px) {
  .lo-website{
    font-size: 2.7rem;
  }
}

@media only screen and (max-width: 991px) {
  .banner {
    background-image: url("../../Images/mobbg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
    padding-bottom: 15rem;
  }
  .title{
    text-align: center;
    margin-top: 5rem;
    font-size: 2rem;
  }
  .lo-website{
    text-align: center;
    font-size: 2rem;
  }
  .lo-banner-img {
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .lo-banner-name {
    text-align: center;
  }
  .lo-banner-nmls {
    text-align: center;
    font-size: 1.1rem;
  }
  .lo-banner-mob {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
    
}

@media only screen and (max-width: 360px) and (min-width: 320px) {
  .lo-website, .title {
    font-size: 1.5rem;
  }
  .lo-banner-mob {
    font-size: 0.9rem;
    width: 90%;
  }
  .lo-banner-nmls {
    font-size: 1rem;
  }
}


@media only screen and (max-width: 991.9px) and (min-width: 768px) {
  .lo-banner-img {
    width: 80%;
    height: 500px;
  }
  .lo-website, .title {
    font-size: 3.5rem;
  }
  .lo-banner-name {
    margin-top: -2rem;
  }
  .banner{
    padding-bottom: 12rem;
  }
}